import { baseUrl } from "../blog/apiCall";

export const getAllJobs = async () => {
  try {
    const res = await fetch(`${baseUrl}/api/jobs?identifier=bionadvisors.com`, {
      method: "GET",
    });
    const data = await res.json();
    return data.jobs;
  } catch (error) {
    console.log(error);
  }
};
