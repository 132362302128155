// interface ValuesProps {}
import styles from "../../styles/team.module.css";

const Values = () => {
  return (
    <>
      <div
        className="flex flex-col items-center py-12 md:py-24 gap-y-4 md:gap-y-8 text-xl md:text-2xl"
        style={{
          height: "fit-content",
          paddingInline: "12vw",
        }}
      >
        <p className="text-[#314256] text-3xl">OUR VALUES</p>
        <p
          className={`px-0 text-center text-4xl flex flex-col lg:gap-y-8 ${styles.value}`}
          style={{ width: "100%" }}
        >
          <div>
            <span className="text-[#FFCC34]">CLIENT CENTRIC</span>.
            <span className="text-[#EE4B11]"> CONFIDENTIALITY</span>.
            <span className="text-[#B58F1D]"> ADAPTABILITY</span>.
          </div>
          <div>
            <span className="text-[#2EB160]"> INNOVATION</span>.
            <span className="text-[#EF8600]"> TRANSPARENCY</span>.
            <span className="text-[#006BF9]"> EXCELLENCE</span>.
          </div>
        </p>
      </div>
      <div className="bg-[#314256] py-10 md:p-20 px-8 md:px-32">
        <p className="text-white text-base md:text-xl uppercase text-justify leading-6 md:leading-10 xl:px-10 lg:tracking-[4px]">
          Bion was born in 2019 when the founders with their collective
          experience of more than two decades from Big 4 & other organizations,
          took the entrepreneurial plunge. Our young and dynamic team is
          dedicated to delivering the highest quality services under the
          guidance of the founders.
        </p>
      </div>
    </>
  );
};

export default Values;
