import checker from "../../assets/checker.png";
import img1 from "./narinder-pal-Vv0oZQ_v48Y-unsplash.jpg";
import img2 from "./shane-rounce-DNkoNXQti3c-unsplash.jpg";
import img3 from "./jared-rice-NTyBbu66_SI-unsplash.png";
import img4 from "./MicrosoftTeams-image (16).png";
// interface CultureProps {}

const Culture = () => {
  const Card = ({
    text,
    img,
    desc,
  }: {
    text: string;
    img: any;
    desc?: string;
  }) => {
    return (
      <div className="flex flex-col w-72 gap-y-4">
        <img src={img} alt="" className="w-72 h-72 object-cover" />
        <p className="text-lg">{text}</p>
        <p className="text-justify text-base">{desc}</p>
      </div>
    );
  };

  return (
    <>
      <div className="bg-[#FFCC34] text-[#314256] flex flex-col gap-y-4 py-10 px-10 lg:px-32 xl:pl-40">
        <p className="text-4xl">Culture at BION</p>
        <div className="flex lg:flex-row flex-wrap lg:flex-nowrap gap-8 justify-center">
          <Card
            text={"Collaboration and Teamwork"}
            img={img1}
            desc="Teamwork is at the heart of the Bion culture. We believe in achieving the best results through collaboration and the collective expertise of our diverse workforce. We foster an environment of idea sharing, knowledge exchange, and celebration of teamwork."
          />
          <Card
            text={"Empowerment and Autonomy"}
            img={img2}
            desc="At Bion, we trust our team members to make impactful decisions and take ownership of their work. Our supportive and empowering environment allows individuals to exercise autonomy, take initiative, and contribute to the company’s success."
          />
          <Card
            text={"Work-Life Balance"}
            img={img3}
            desc="We value employee well-being and work-life balance, believing that a healthy and fulfilled team is essential for individual as well as organizational success. We offer flexible work arrangements, and promote a balanced, supportive, inclusive, and family-first culture."
          />
          <Card
            text={"Employee engagement"}
            img={img4}
            desc="We take fun VERY seriously here. From team-building activities to social events and team vacations, we like to celebrate small and big wins, providing opportunities for our team to unwind, connect, and recharge."
          />
        </div>
      </div>
    </>
  );
};

export default Culture;
