import styles from "../styles/testimonial.module.css";

interface TestimonialCardProps {
  text: string;
  title: string;
  img: any;
}

const TestimonialCard = ({ text, title, img }: TestimonialCardProps) => {
  return (
    <>
      <div className={styles.card_container}>
        <div
          className={styles.img_part}
          style={
            {
              // filter: "grayscale(100%)",
            }
          }
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundImage: `url('${img}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: 10,
              filter: "grayscale(100%",
            }}
          ></div>
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(79, 55, 245, .5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "100",
            }}
          >
            <p style={{ color: "white", fontSize: "1.2rem" }}>{title}</p>
          </div>
        </div>
        <div className={styles.content}>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
