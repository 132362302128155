import { useEffect, useLayoutEffect } from "react";
import styles from "../styles/hero.module.css";
import { useLocation } from "react-router-dom";
interface HeroProps {
  text: string;
  fontSize?: string;
  isCompanyPage?: boolean;
  videoSrc: any;
  isLandingPage?: boolean;
  styless?: any;
  isGlobalPage?: boolean;
}

const Hero = ({
  text,
  fontSize,
  isCompanyPage = false,
  videoSrc,
  isLandingPage = false,
  isGlobalPage = false,
  styless = {},
}: HeroProps) => {
  const width = window.innerWidth;
  const location = useLocation().pathname;

  // console.log(location);

  return (
    <>
      <div
        className={
          isCompanyPage || isGlobalPage
            ? `relative flex flex-col ${
                isGlobalPage ? "items-center md:items-end" : "items-center"
              } justify-center h-screen mt-0`
            : styles.hero_container
        }
        style={
          width < 600 ? (location === "/career" ? { height: "100vw" } : {}) : {}
        }
      >
        <p
          className={`text-3xl ${
            isLandingPage ? "md:text-6xl" : "md:text-4xl"
          } text-white z-20 px-8 lg:px-40 xl:mt-2`}
          style={{
            fontSize: width > 1024 ? fontSize : "30px",
            // border: "2px solid red",
            ...styless,
          }}
        >
          {text}
        </p>
        {isCompanyPage && (
          <p
            className="text-xl md:text-2xl text-white z-20 px-8 lg:px-56 mt-8 text-center"
            style={styless}
          >
            To empower startups with expert financial guidance and innovative
            solutions, while cultivating a reputation as a premium consulting
            firm recognized for excellence and exceptional client service.
          </p>
        )}
        {isGlobalPage && (
          <p className="text-xl md:text-xl text-white z-20 px-8 lg:px-40 mt-8 text-left md:text-right w-full md:w-[70%]">
            With our assistance, you can confidently establish and
            <br />grow your business in this vibrant and ever-evolving market.
          </p>
        )}

        {window.innerWidth < 800 ? (
          location === "/career" ? (
            <video
              muted={true}
              // @ts-ignore
              autoplay="autoplay"
              playsinline="playsinline"
              loop={true}
              style={{
                width: "100vw",
                height: "100%",
                position: "absolute",
                top: 0,
                objectFit: "cover",
              }}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          ) : (
            <div
              className={styles.video}
              dangerouslySetInnerHTML={{
                __html: `<video
            		id="vid"
            		autoPlay
            		muted
            		loop
            		playsInline
            		style="height: 100%; width:100%; z-index: 1000; object-fit:cover; ${
                  location === "/team" ? "object-position:right" : ""
                }"
            >
            	<source src=${videoSrc} type="video/mp4"/>
            </video>`,
              }}
            ></div>
          )
        ) : (
          <div
            className={styles.video}
            dangerouslySetInnerHTML={{
              __html: `<video
						id="vid"
						autoplay="autoplay"
						muted
						loop
						playsinline
						style="height: 100%; width:100%; z-index: 1000; object-fit:cover;"
				>
					<source src=${videoSrc} type="video/webm"/>
				</video>`,
            }}
          ></div>
        )}

        {isLandingPage && (
          <div
            // className="flex flex-row w-full items-center justify-center text-[#FCFCFC] z-20 opacity-60 absolute bottom-0"
            className={styles.hero_data_container}
          >
            {/* <div
              className="flex bg-[#CD9803] items-center w-full justify-center w-1/2 px-1"
              style={{ height: "100%", border: "2px solid red" }}
            > */}
            <p
              className="text-lg md:text-4xl w-full text-center lg:pl-16"
              style={{
                background: "#cd9803",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              YOUR FINANCE TEAM.
            </p>
            {/* </div> */}
            <div
              className="flex bg-[rgba(205,152,3,0.8)] w-full items-center justify-start "
              style={{ padding: "10px 0" }}
            >
              <p className="text-[.6rem] md:text-xl text-left px-4 lg:pl-12 w-[80%]">
                Rendering extensive range of services in the areas of finances,
                taxes & business consultancy.
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Hero;
