import checker from "../../assets/checker.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import img1 from "../../assets/images/images/man.png";
import img2 from "../../assets/images/images/man(1).png";
import img3 from "../../assets/images/images/woman.png";
import styles from "../../styles/home.module.css";

const Testimonials = () => {
  const wide = window.innerWidth;

  const Card = ({
    desc,
    l1,
    l2,
    img,
  }: {
    desc: any;
    l1: string;
    l2: string;
    img: any;
  }) => {
    return (
      <>
        <div className={styles.card}>
          <img src={img} alt="" />
          <div>
            <p className={styles.card_para}>{desc}</p>
            <p className="mt-3 underline">{l1}</p>
            <p className="mt-1 text-sm italic">{l2}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={styles.card_container}>
        <Card
          desc={
            "The Bion Advisors team is efficient and prompt, and values the founder’s time. They provide relevant information in a timely fashion, proactively addressing potential issues well ahead of time. Our two-year association has been satisfying, with excellent service and individual attention."
          }
          l1={"Founder of a SaaS company,"}
          l2={"Bion provides Virtual CFO services."}
          img={img1}
        />
        <Card
          desc={
            "Nitin and Deepika excelled in creating an optimal tax structure for our group companies. They went above and beyond, coordinating with vendors from various countries and ensuring a complete setup. Nitin’s quick grasp of business dynamics and financial expertise, combined with Deepika’s knowledge of corporate and international tax, provided clarity and substantial cost savings."
          }
          l1={"Director of an Outsourcing Business,"}
          l2={"Bion provided Tax Advisory Services."}
          img={img2}
        />
        <Card
          desc={
            "I’ve been using Bion Advisors for tax planning and ITR filing services for a few years now. Despite my complex income sources (foreign income, ESOPs, RSUs, and perquisites), their team consistently provides excellent assistance. They fulfill requirements, explain clearly, and simplify tax computation, ensuring I understand the whole process."
          }
          l1={"Vice President at MNC,"}
          l2={"Bion provides Tax planning & IT Filing services."}
          img={img3}
        />
      </div>
      <div className={styles.card_container_mob}>
        <Splide
          options={{
            arrows: wide < 800 ? true : false,
            pagination: false,
            perPage: wide < 800 ? 1 : 4,
            gap: wide < 800 ? 20 : 250,
            drag: wide < 800 ? true : false,
            padding: "0",
            cover: true,
            type: wide < 800 ? "loop" : "",
            autoplay: wide < 800 ? true : false,
          }}
        >
          <SplideSlide>
            <Card
              desc={
                "The Team at Bion Advisors is efficient and prompt, and understand that the founder’s time is valuable. All the relevant information is made available to us in promptly and they highlight potential issues before they crop up. We have been associated with them for over two years now and quite happy with the kind of service and attention we receive."
              }
              l1={"Founder of a SaaS company,"}
              l2={"Bion provides Virtual CFO services."}
              img={img1}
            />
          </SplideSlide>
          <SplideSlide>
            <Card
              desc={
                "We wanted to form an optimal tax structure for our group companies and Nitin & Deepika did a fabulous job, going above and beyond to set it up completely including coordinating with vendors from different countries. Nitin’s ability to quickly understand business dynamics and his expertise in finance and Deepika’s knowledge on corporate and international tax together gave us clarity and saved us a great deal of money."
              }
              l1={"Director of an outsourcing business,"}
              l2={"Bion provided Tax Advisory Services."}
              img={img2}
            />
          </SplideSlide>
          <SplideSlide>
            <Card
              desc={
                "I have been availing services of tax planning and ITR filing from Bion Advisors for quite some time now, as I have complex sources of income from my employers such as foreign income, ESOPs, RSU’s, various perquisites etc. The team at Bion have been doing an excellent and thorough job. They don’t only do what’s needed but also provide a rationale behind a particular treatment and decode the entire tax computation in simplest way possible to make me understand what’s happening."
              }
              l1={"Vice President at MNC,"}
              l2={"Bion provides Tax planning & IT Filing services."}
              img={img3}
            />
          </SplideSlide>
        </Splide>
      </div>
    </>
  );
};

export default Testimonials;
