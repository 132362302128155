import checker from "../../assets/checkerH.png";
import seed from "../../assets/images/images/christine-lENhFCC2tGY-unsplash.webp";
import styles from "../../styles/home.module.css";

const Info = () => {
  return (
    <>
      <div className="flex flex-col justify-between py-8 lg:py-16 px-8 lg:px-[12vw] bg-[#FFCC34] text-[#314256]">
        <p className="text-xl lg:text-4xl">
          With you from pre-revenue stage, to funding, to exit!
        </p>
        <div className={styles.content_wrapper}>
          <img
            src={seed}
            alt=""
            style={{ aspectRatio: "16/9", objectFit: "cover" }}
          />
          <div
            className={`items-start text-base lg:w-1/2 gap-y-4 lg:gap-y-4 xl:gap-y-10 ${styles.content_text}`}
          >
            <p>
              We at Bion Advisors specialise in CFO / Finance co-founder
              services for early-stage technology ventures – right from the
              funding stage to day-to-day accounting and taxes, and high-level
              financial strategy and decision-making.
            </p>
            <p>
              As a founder, it’s important to have partners around you that can
              scale and handle the complexity of a high-growth startup. Tech
              founders and CEOs rely on us to handle their finance function and
              give them timely and relevant advice, so that they can focus on
              developing their product without a care.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
