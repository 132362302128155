import { baseUrl } from "../pages/blog/apiCall";

interface ContactProps {
  firstName: string;
  personalEmail: string;
  mobileNumber: string;
  reason1: string;
  reason2: string;
}

export const submitContact = async ({
  firstName,
  personalEmail,
  mobileNumber,
  reason1,
  reason2,
}: ContactProps) => {
  try {
    const response = await fetch(`${baseUrl}/api/contacts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        identifier: "bionadvisors.com",
        firstName,
        personalEmail,
        mobileNumber,
        reason1,
        reason2,
        status: "lead",
        createdAt: new Date(),
      }),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};
