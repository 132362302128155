interface OpeningProps {
  data: any;
}

interface BoxProps {
  title: string;
  exp: string;
  desc: string;
  qualification: string;
  numberOfOpenings: string;
}

const Opening = ({ data }: OpeningProps) => {
  const Box = ({
    qualification,
    title,
    exp,
    desc,
    numberOfOpenings,
  }: BoxProps) => {
    return (
      <div className="flex flex-col gap-2 text-[#314256] border border-[#CD9803] p-6 pb-8 w-64 text-base">
        <div className="">
          <span style={{ fontWeight: 600 }}>Title: </span>
          <span>{title}</span>
        </div>
        <div className="">
          <span style={{ fontWeight: 600 }}>Qualification: </span>
          <span
            dangerouslySetInnerHTML={{
              __html: qualification.replace(/&nbsp;/g, " "),
            }}
          ></span>
        </div>
        <div className="">
          <span style={{ fontWeight: 600 }}> Experience: </span>
          <span>{exp}</span>
        </div>
        <div className="">
          <span style={{ fontWeight: 600 }}>Description: </span>
          <span
            dangerouslySetInnerHTML={{
              __html: desc.replace(/&nbsp;/g, " "),
            }}
          ></span>
        </div>
        <div className="">
          <span style={{ fontWeight: 600 }}>Openings: </span>
          <span>{numberOfOpenings}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="px-8 lg:mx-32 my-8">
        <p className="text-[#CD9803] text-4xl">Current Openings</p>
        <div className="flex flex-row flex-wrap gap-8 mt-4">
          {data.map((step: any, index: number) => {
            return (
              <Box
                key={index}
                qualification={step.qualification}
                title={step.title}
                exp={step.experience}
                desc={step.description}
                numberOfOpenings={step.numberOfOpenings}
              />
            );
          })}
        </div>
      </div>
      <hr className="h-0.5 bg-[#CD9803] w-56 lg:w-96 mx-auto" />
    </>
  );
};

export default Opening;
