import AWS from "aws-sdk";

const uploadFile = ({ file, name }: { file: any; name: any }) => {
	AWS.config.update({
		accessKeyId: process.env.REACT_APP_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_ACCESS_KEY_SECRET,
		region: process.env.REACT_APP_APP_REGION,
	});

	const s3 = new AWS.S3();

	const params: any = {
		Bucket: process.env.REACT_APP_BUCKET_NAME,
		Key: "bion/" + name,
		Body: file,
		ACL: "public-read",
	};

	return s3.putObject(params).promise();
};

export default uploadFile;
