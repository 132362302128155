import { useLocation, useNavigate } from "react-router-dom";
import styles from "../styles/layout.module.css";
import { useState } from "react";
import { ReactComponent as Logo } from "../assets/logo.svg";
import Cross from "../assets/cross.svg";
import { ReactComponent as FB } from "../assets/icon/facbook.svg";
import { ReactComponent as INSTA } from "../assets/icon/insta.svg";
import { ReactComponent as TWITTER } from "../assets/icon/twitter.svg";
import { ReactComponent as LINKEDIN } from "../assets/icon/linkedin.svg";

// interface NavbarProps {}

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [global, setGlobal] = useState(true);
  const navigate = useNavigate();

  const location = useLocation().pathname;

  return (
    <>
      {location === "/" && global && (
        <div
          className="w-full h-8 bg-[#BB9C71] z-[120] text-center text-xs flex justify-center items-center text-white
                fixed top-0 left-0"
          style={{
            fontFamily: "Josefin Sans, sans-serif",
          }}
        >
          Bion Advisors are now in&nbsp;
          <a href="/global" className="underline">
            Dubai
          </a>
          .
          <div
            className="absolute right-3 top-0 bottom-0 flex items-center justify-center cursor-pointer"
            onClick={() => setGlobal(false)}
          >
            <img src={Cross} alt="" height={10} width={10} />
          </div>
        </div>
      )}
      <div
        className={`${styles.nav_container} ${
          location === "/" && global ? "top-8" : "top-0"
        }`}
        style={
          menu
            ? { background: "white" }
            : {
                background: "rgba(255, 253, 249, 0.5)",
                backdropFilter: "blur(5px)",
              }
        }
      >
        {/* send logo here to attach here */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          <Logo style={{ height: "40px", width: "100px" }} />
        </div>
        <div style={{ flexGrow: 1 }}></div>
        <ul className={styles.right_nav_elements}>
          <li
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </li>
          <li
            onClick={() => {
              navigate("/services");
            }}
          >
            Services
          </li>
          <li
            onClick={() => {
              navigate("/global");
            }}
          >
            Global
          </li>
          <li
            onClick={() => {
              navigate("/team");
            }}
          >
            Team
          </li>
          <li
            onClick={() => {
              navigate("/blog");
            }}
          >
            Insights
          </li>
          <li
            onClick={() => {
              navigate("/career");
            }}
          >
            Careers
          </li>
          <li
            onClick={() => {
              navigate("/");
              navigate("#contact");
            }}
          >
            Contact
          </li>
        </ul>
        <div
          className={styles.menu_btn}
          onClick={() => {
            setMenu((val) => !val);
          }}
        >
          <div className={`${styles.one} ${menu ? styles.one_move : {}}`}></div>
          <div
            className={`${styles.two}  ${menu ? styles.two_move : {}}`}
          ></div>
          <div
            className={`${styles.three} ${menu ? styles.three_move : {}}`}
          ></div>
        </div>
      </div>
      <div
        className={`${styles.menu} ${menu ? `${styles.menu_open}` : {}}`}
        style={
          location === "/" && global && menu ? { top: "calc(70px + 2rem)" } : {}
        }
      >
        {menu && (
          <ul className={styles.right_nav_elements_mob}>
            <li
              onClick={() => {
                navigate("/");
                setMenu(false);
              }}
            >
              Home
            </li>
            <li
              onClick={() => {
                navigate("/services");
                setMenu(false);
              }}
            >
              Services
            </li>
            <li
              onClick={() => {
                navigate("/global");
                setMenu(false);
              }}
            >
              Global
            </li>
            <li
              onClick={() => {
                navigate("/team");
                setMenu(false);
              }}
            >
              Team
            </li>
            <li
              onClick={() => {
                navigate("/blog");
                setMenu(false);
              }}
            >
              Insights
            </li>
            <li
              onClick={() => {
                navigate("/career");
                setMenu(false);
              }}
            >
              Careers
            </li>
            <li
              onClick={() => {
                navigate("/");
                navigate("#contact");
                setMenu(false);
              }}
            >
              Contact
            </li>
          </ul>
        )}

        {menu && (
          <div className="flex flex-row items-center justify-center gap-4 md:gap-2 pt-4">
            <a
              href={"https://www.facebook.com/bionadvisorsofficial/"}
              target="_blank"
            >
              <div className={styles.icon_container}>
                <FB className={styles.icons} />
              </div>
            </a>
            <a href="https://www.instagram.com/bion_advisors" target="_blank">
              <div className={styles.icon_container}>
                <INSTA className={styles.icons} />
              </div>
            </a>
            <a href="https://twitter.com/BionAdvisors" target="_blank">
              <div className={styles.icon_container}>
                <TWITTER className={styles.icons} />
              </div>
            </a>
            <a
              href="https://in.linkedin.com/company/bionadvisors"
              target="_blank"
            >
              <div className={styles.icon_container}>
                <LINKEDIN className={styles.icons} />
              </div>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
