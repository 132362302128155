interface StyledInputboxProps {
  id?: string;
  type?: string;
  placeHolder?: string;
  accept?: string;
  val?: string | number | readonly string[] | undefined;
  onChanged?: React.ChangeEventHandler<HTMLInputElement>;
}

const StyledInputbox = ({
  type = "text",
  placeHolder,
  id,
  accept,
  val,
  onChanged,
}: StyledInputboxProps) => {
  return (
    <>
      <div className="w-full" style={{ border: "none" }}>
        <input
          type={type === "date" ? "text" : type}
          id={id}
          accept={accept}
          placeholder={placeHolder}
          value={val}
          onChange={onChanged}
          onFocus={(event: any) => {
            if (type === "date") event.target.type = "date";
          }}
          name=""
          className="text-lg text-[#5f7d95] py-2 px-4 w-full border border-[#314256] tracking-wide"
          style={{ borderRadius: 0 }}
        />
      </div>
    </>
  );
};

export default StyledInputbox;
