import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./layout/Index";
import Home from "./pages/home/Index";
import Services from "./pages/services/Index";
// import Team from "./pages/team/Index";
import Team from "./pages/team/Index";
import Career from "./pages/career/Index";
import Blog from "./pages/blog/Index";
import BlogById from "./pages/blog/BlogById";
import { Toaster } from "react-hot-toast";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import axios from "axios";
import Global from "./pages/global";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/global",
        element: <Global />,
      },
      {
        path: "/team",
        element: <Team />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/blog/:id",
        element: <BlogById />,
      },
      {
        path: "/career",
        element: <Career />,
      },
      {
        path: "terms-and-conditions",
        element: <Terms />,
      },
      {
        path: "privacy-and-policy",
        element: <Privacy />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AccessCheck = ({ children }: { children: any }) => {
  return <>{children}</>;
};

root.render(
  <React.StrictMode>
    <Toaster
      toastOptions={{
        success: {
          iconTheme: {
            primary: "#CD9804",
            secondary: "#CD9804",
          },
        },
      }}
    />
    <RouterProvider router={router} />
  </React.StrictMode>
);
