import ApplyNow from "../../components/ApplyNow";
import Hero from "../../components/Hero";
import Culture from "./Culture";
import Opening from "./Opening";
import { ScrollRestoration } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllJobs } from "./apiCalls";

// interface CareerProps {}

const Career = () => {
  const [opening, setOpening]: [any, any] = useState([]);

  useEffect(() => {
    getAllJobs()
      .then((res) => {
        // testing
        setOpening([...res]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Hero
        text=""
        videoSrc={
          "https://business-1-images.s3.ap-south-1.amazonaws.com/bion/hero.mp4"
        }
      />
      <Culture />
      {opening.length !== 0 && <Opening data={opening} />}
      <ApplyNow openings={opening} />
    </>
  );
};

export default Career;
