import axios from "axios";
import StyledInputbox from "./StyledInputbox";
import { useState } from "react";
import uploadFile from "../utils/uploadFile";
import { toast } from "react-hot-toast";
import styles from "../styles/career.module.css";

// interface ApplyNowProps {}

const ApplyNow = ({ openings }: any) => {
  console.log(openings);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [applyDate, setApplyDate] = useState("");
  const [position, setPosition] = useState("");
  const [education, setEducation] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [experience, setExperience] = useState("");
  const [remarks, setRemarks] = useState("");
  const [cv, setCv]: [any, any] = useState("");
  const [location, setLocation] = useState("");
  const [other, setOther] = useState("");

  const onFileSelect = (event: any) => {
    setCv(event.target.files[0]);
  };

  const removeExtension = (filename: string) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  const validate = () => {
    let text = "";

    if (!fName) {
      text = "First Name is required.";
    }

    // if (!lName && !text) {
    //   text = "Last Name is required.";
    // }

    if (!email && !text) {
      text = "Email is required.";
    }

    if (!location && !text) {
      text = "Location is required.";
    }

    if (!education && !text) {
      text = "Education Qualification is required.";
    }

    if (!experience && !text) {
      text = "Experience is required.";
    }

    if (!applyDate && !text) {
      text = "DOB is required.";
    }

    if (!mobile && !text) {
      text = "Mobil number is required.";
    }

    if (mobile && !text) {
      if (String(mobile).length !== 10) {
        text = "Please enter a valid mobile number.";
      }
    }

    if (!cv && !text) {
      text = "CV/Resume is required.";
    }

    if (!noticePeriod && !text) {
      text = "Notice period is required.";
    }

    if (text) {
      toast.error(text);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      const promise = new Promise((resolve, reject) => {
        const timestamp = new Date();

        const filename = `${removeExtension(
          //@ts-ignore
          cv.name
        )}_${timestamp.getHours()}${timestamp.getMinutes()}${timestamp.getSeconds()}${timestamp.getMilliseconds()}.${
          //@ts-ignore
          cv.type.split("/")[1]
        }`;

        uploadFile({ file: cv, name: filename }).then(() => {
          axios({
            method: "post",
            url: "https://backend.cftcommerce.com/api/aspirants",
            data: {
              identifier: "bionadvisors.com",
              firstName: fName,
              lastName: lName,
              email: email,
              experience: experience,
              mobileNumber: mobile,
              cvUrl: filename,
              noticePeriod: noticePeriod,
              bioShort: remarks,
              currentCity: location,
              qualification: education === "Other" ? other : education,
              appliedPosition: position,
              joiningStatus: "applied",
            },
          })
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              reject();
            });
        });
      });

      toast.promise(
        promise,
        {
          loading: "Submitting Application...",
          success: () => {
            setFName("");
            setLName("");
            setEmail("");
            setMobile("");
            setApplyDate("");
            setPosition("");
            setEducation("");
            setNoticePeriod("");
            setExperience("");
            setRemarks("");
            setCv("");
            setLocation("");
            setOther("");

            return "Submitted Successfully.";
          },
          error: "Error when submitting the application.",
        },
        { success: { duration: 2000 }, error: { duration: 2000 } }
      );
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center px-6 bg-[#FCFCFC] gap-x-24 gap-y-4 py-12 md:py-16">
        <p className="md:-rotate-90 text-4xl md:text-7xl text-[#cd9803]">
          Apply Now
        </p>
        <div className="flex flex-col gap-x-40 gap-y-8 md:w-fit">
          <div className="flex flex-col md:flex-row gap-x-14 gap-y-5 md:w-[50vw]">
            <div className="flex flex-col gap-y-5 w-full">
              {/* <StyledInputbox type="text" placeHolder="Department" /> */}
              <StyledInputbox
                type="text"
                placeHolder="Full Name"
                val={fName}
                onChanged={(event: any) => {
                  setFName(event.target.value);
                }}
              />
              <StyledInputbox
                type="email"
                placeHolder="Email Address"
                val={email}
                onChanged={(event: any) => {
                  setEmail(event.target.value);
                }}
              />
              <StyledInputbox
                type="text"
                placeHolder="Current City"
                val={location}
                onChanged={(event: any) => {
                  setLocation(event.target.value);
                }}
              />

              <StyledInputbox
                type="text"
                placeHolder="Current Notice Period"
                val={noticePeriod}
                onChanged={(event: any) => {
                  setNoticePeriod(event.target.value);
                }}
              />

              <div className={styles.select_container}>
                <select
                  value={education}
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0 10px",
                    background: "transparent",
                    color: education === "" ? "#9fa5b1" : "#5f7d95",
                  }}
                  onChange={(event: any) => {
                    setEducation(event.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Education Qualification
                  </option>
                  <option value={"Charted Accountant"}>
                    Chartered Accountant
                  </option>
                  <option value={"Semi Qualified CA"}>Semi Qualified CA</option>
                  <option value={"MBA"}>MBA</option>
                  <option value={"M.COM"}>M.COM</option>
                  <option value={"B.COM"}>B.COM</option>
                  <option value={"Company Secretary"}>Company Secretary</option>
                  <option value={"CMA"}>CMA</option>
                  <option value={"Other"}>Other (please specify)</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-y-5 w-full">
              {/* <StyledInputbox type="text" placeHolder="Position" /> */}
              <StyledInputbox
                type="date"
                placeHolder="Date of Birth"
                val={applyDate}
                onChanged={(event: any) => {
                  setApplyDate(event.target.value);
                }}
              />
              <StyledInputbox
                type="text"
                placeHolder="Mobile Number"
                val={mobile}
                onChanged={(event: any) => {
                  setMobile(event.target.value);
                }}
              />
              <div className={styles.select_container}>
                <select
                  value={position}
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0 10px",
                    background: "transparent",
                    color: education === "" ? "#9fa5b1" : "#5f7d95",
                  }}
                  onChange={(event: any) => {
                    setPosition(event.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Position
                  </option>
                  {openings.map((val: any, index: any) => (
                    <option value={val.title}>{val.title}</option>
                  ))}
                </select>
              </div>
              {/* <StyledInputbox
                type="select"
                placeHolder="Position"
                val={position}
                onChanged={(event: any) => {
                  setPosition(event.target.value);
                }}
              /> */}
              <div className={styles.select_container}>
                <select
                  value={experience}
                  style={{
                    height: "100%",
                    width: "100%",
                    padding: "0 10px",
                    background: "transparent",
                    color: experience === "" ? "#9fa5b1" : "#5f7d95",
                  }}
                  onChange={(event: any) => {
                    setExperience(event.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Professional Experience
                  </option>
                  <option>Fresher</option>
                  <option>1 – 2 years</option>
                  <option>3 – 5 years</option>
                  <option>+5 years</option>
                </select>
              </div>
              {education === "Other" && (
                <StyledInputbox
                  type="text"
                  placeHolder="Please Specify"
                  val={other}
                  onChanged={(event: any) => {
                    setOther(event.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <StyledInputbox
            type="text"
            placeHolder="Message (optional)"
            val={remarks}
            onChanged={(event: any) => {
              setRemarks(event.target.value);
            }}
          />

          <div
            className="h-12"
            style={{
              border: "1px solid black",
              display: "flex",
              alignItems: "center",
              paddingInline: "10px",
              gap: "10px",
              position: "relative",
            }}
          >
            <button
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                background: "rgba(0,0,0,.1)",
                fontSize: ".8rem",
                padding: "5px",
              }}
            >
              Upload CV (.pdf)
            </button>

            <p>{cv ? cv?.name : "No file chosen"}</p>

            <input
              type="file"
              id="cv"
              accept="application/pdf"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 10,
                opacity: 0,
              }}
              onChange={(event: any) => {
                onFileSelect(event);
              }}
            />
          </div>
          <button
            onClick={handleSubmit}
            className="text-[#FCFCFC] text-base bg-[#CD9803] h-[46px]"
          >
            Submit Application
          </button>
        </div>
      </div>
    </>
  );
};

export default ApplyNow;
