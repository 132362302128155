import Marquee from "react-fast-marquee";
import fb from "../assets/checker.png";
import * as images from "../assets/clients/img.ts";

interface MarqueProps {
	bg?: string;
	title: string;
	text?: string;
	textColor?: string;
}

const Marque = ({ bg, title, text, textColor }: MarqueProps) => {
	return (
		<>
			<div
				className="px-6 md:px-0 py-4 md:py-10 flex flex-col md:flex-row md:items-center gap-y-4 md:gap-y-0 gap-x-6 md:gap-x-12"
				style={{
					backgroundColor: bg,
				}}
			>
				<p
					className="md:-rotate-90 text-4xl md:text-7xl tracking-wide ml-0 md:ml-12 lg:ml-20"
					style={{
						color: textColor,
					}}
				>
					{title}
				</p>
				<div className="flex flex-col gap-y-4 md:gap-y-8 overflow-x-hidden">
					{text && (
						<p className="text-base md:text-lg mr-8 tracking-wide">{text}</p>
					)}
					<Marquee>
						{[...Array(10)].map((_, index: any) => {
							return (
								<div
									className="w-24 md:w-48 h-24 md:h-48 ml-4 md:ml-8"
									style={{ background: "white", paddingInline: "15px" }}
								>
									<img
										className="object-contain w-24 md:w-48 h-24 md:h-48 grayscale"
										src={images["img" + (index + 1)]}
										alt=""
									/>
								</div>
							);
						})}
					</Marquee>
					<Marquee>
						{[...Array(10)].map((_, index: any) => {
							return (
								<div
									className="w-24 md:w-48 h-24 md:h-48 ml-4 md:ml-8"
									style={{ background: "white", paddingInline: "15px" }}
								>
									<img
										className="object-contain w-24 md:w-48 h-24 md:h-48 grayscale"
										src={images["img" + (index + 11)]}
										alt=""
									/>
								</div>
							);
						})}
					</Marquee>
				</div>
			</div>
		</>
	);
};

export default Marque;
