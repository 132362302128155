import bion from "../assets/logo.svg";
// import fb from "../assets/facebook.png";
// import insta from "../assets/instagram.png";
// import linkedin from "../assets/linkedin.png";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../styles/footer.module.css";
import { ReactComponent as FB } from "../assets/icon/facbook.svg";
import { ReactComponent as INSTA } from "../assets/icon/insta.svg";
import { ReactComponent as TWITTER } from "../assets/icon/twitter.svg";
import { ReactComponent as LINKEDIN } from "../assets/icon/linkedin.svg";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;

  return (
    <>
      <hr className="h-0.5 w-full bg-[#CD9803]" />
      <footer className="flex flex-col md:flex-row items-center md:items-start justify-around w-full py-4 md:px-8 md:py-8 mx-auto gap-4 bg-[#FCFCFC] text-[#314256]">
        <div className="flex flex-col items-center md:items-start gap-2 lg:ml-12">
          <img className="w-32 object-contain" src={bion} alt="bion" />
          <p className="text-base">Your Finance Team</p>
          <a href="mailto:contact@bionadvisors.com" className="text-base">
            contact@bionadvisors.com
          </a>
        </div>
        <div className="flex flex-col gap-1 items-center md:items-start">
          <p className="text-[#CD9803] text-xl">Services</p>
          <div className="flex flex-row md:flex-col gap-x-2 gap-y-2 text-[10px] md:text-base">
            <p
              className="cursor-pointer"
              onClick={(event: any) => {
                if (location === "/services") {
                  document
                    .getElementById("startup")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                  navigate("/services#startup");
                }
              }}
            >
              Startup Advisory
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                // navigate("/services");
                // navigate("#control", { replace: true });

                if (location === "/services") {
                  document
                    .getElementById("control")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                  navigate("/services#control");
                }
              }}
            >
              Finance Controllership
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                // navigate("/services");
                // navigate("#strategy", { replace: true });
                if (location === "/services") {
                  document
                    .getElementById("strategy")
                    ?.scrollIntoView({ behavior: "smooth", block: "center" });
                } else {
                  navigate("/services#strategy");
                }
              }}
            >
              Finance Strategy
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-center md:items-start">
          <p className="text-[#CD9803] text-xl">Support</p>
          <div className="flex flex-row md:flex-col gap-x-2 gap-y-2 text-[10px] md:text-base">
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/privacy-and-policy");
              }}
            >
              Privacy Policy
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              Terms & Conditions
            </p>
            <p
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
                navigate("#contact");
              }}
            >
              Contact Us
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row items-center justify-center gap-4 md:gap-2">
            <a
              href={"https://www.facebook.com/bionadvisorsofficial/"}
              target="_blank"
            >
              <div className={styles.icon_container}>
                <FB className={styles.icons} />
              </div>
            </a>
            <a href="https://www.instagram.com/bion_advisors" target="_blank">
              <div className={styles.icon_container}>
                <INSTA className={styles.icons} />
              </div>
            </a>
            <a href="https://twitter.com/BionAdvisors" target="_blank">
              <div className={styles.icon_container}>
                <TWITTER className={styles.icons} />
              </div>
            </a>
            <a
              href="https://in.linkedin.com/company/bionadvisors"
              target="_blank"
            >
              <div className={styles.icon_container}>
                <LINKEDIN className={styles.icons} />
              </div>
            </a>
          </div>
          <p className="text-[#CD9803]">© BION Advisors Pvt. Ltd.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
