export const baseUrl = "https://backend.cftcommerce.com";

export const getAllBlogs = async (pageNo: any) => {
  console.log(pageNo);
  try {
    const res = await fetch(
      `${baseUrl}/api/blogs/?identifier=bionadvisors.com&status=live&page=${pageNo}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getBlogById = async (id?: string) => {
  try {
    const res = await fetch(
      `${baseUrl}/api/blogs/?identifier=bionadvisors.com&slug=${id}`,
      {
        method: "GET",
      }
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
