import { useNavigate } from "react-router-dom";
import { imageLink } from "./Index";

interface CardProps {
  src?: string;
  id: string;
  desc: string;
  text: string;
}

const Card = ({ src, id, desc, text }: CardProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="h-72 w-full cursor-pointer flex flex-col relative"
        onClick={() => {
          navigate(`/blog/${id}`);
        }}
      >
        <img
          src={`${imageLink}/${src}`}
          alt=""
          className="h-72 w-full object-cover absolute"
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "rgba(0,0,0,.3)",
          }}
        ></div>
        <p className="z-20 px-4 mt-4 text-white" style={{ fontSize: "1.3rem" }}>
          {desc}
        </p>
        <p className="z-20 px-4 mt-8 text-white">{text}</p>
      </div>
    </>
  );
};

export default Card;
