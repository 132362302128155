import styles from "../../styles/team.module.css";
import shiva from "../../assets/founding_team/shiva.webp";
import venkatesh from "../../assets/founding_team/venkatesh.webp";
import narendra from "../../assets/founding_team/narendra.webp";
import neha from "../../assets/founding_team/neha.webp";
import naveen from "../../assets/founding_team/naveen.webp";
import nitin from "../../assets/founding_team/nitin.webp";
import deepika from "../../assets/founding_team/deepika.webp";
import deepsi from "../../assets/founding_team/deepsi.webp";
import kanhaiya from "../../assets/founding_team/kanhaiya.webp";
import mahammad from "../../assets/founding_team/mahammad.webp";
import aslam from "../../assets/founding_team/aslam.webp";
import mailika from "../../assets/founding_team/mailika.webp";
import linkedin from "../../assets/linkedin_white.png";
import { Link } from "react-router-dom";

// interface DetailProps {}

interface CardProps {
  name: string;
  img?: string;
}

const Detail = () => {
  const Card = ({ name, img }: CardProps) => {
    return (
      <div className={styles.card}>
        <img src={img} alt="" />
        <div className={styles.card_tag}>
          <p>{name}</p>
        </div>
      </div>
    );
  };

  const Item = ({
    rev = false,
    text,
    img,
    url,
    name,
  }: {
    rev: boolean;
    text: string;
    img: string;
    url: string;
    name: string;
  }) => {
    return (
      <div
        className={`flex flex-col md:flex-row ${
          rev ? "flex-row" : "md:flex-row-reverse"
        } mx-10 lg:mx-56 items-center`}
      >
        <img src={img} alt="" className="w-64 h-72 object-cover" />
        <div className="bg-[#314256] w-full md:h-72 flex flex-col justify-center md:gap-y-4 py-4 md:py-0">
          <div className="flex flex-row px-10 items-center">
            <p className="text-base text-white self-center">{name}</p>
            <Link to={url} className="w-5 h-5 ml-4">
              <img className="w-5 object-contain h-5" src={linkedin} alt="" />
            </Link>
          </div>
          <p className="px-10 text-xs md:text-[12px] lg:text-[16px] text-white text-justify">
            {text}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col items-center py-12 md:pt-20 gap-y-4 md:gap-y-8 text-xl md:text-2xl">
        <p className="text-[#314256] text-2xl md:text-4xl uppercase">
          Founding Team
        </p>
      </div>
      <div className="flex flex-col gap-y-4 my-20 mt-4">
        <Item
          name="Nitin Jogad"
          url="https://www.linkedin.com/in/nitinjogad/"
          img={nitin}
          rev={true}
          text="Nitin has over 10 years experience in Statutory Audit, Accounting
            and Finance. He has been previously associated with KPMG and Ralph
            Robert and Associates, where he built a rich exposure to small
            businesses, MNCs and Fortune 500 companies across industries. At
            Bion, he heads the finance function of various tech start-ups. While
            he specializes in setting up and managing entire finance function
            for organization and startups, he’s built a reputation as being the
            right hand man for founders to blindly rely on to get almost
            everything done."
        />
        <Item
          name="Deepika P"
          url="https://www.linkedin.com/in/deepika-p/"
          img={deepika}
          rev={false}
          text="Deepika has over 10 years of post qualification experience in corporate tax, international tax and litigation. She was previously associated with KPMG in the field of international corporate tax and advisory.
          She heads the DT, IDT and FEMA regulations practice at Bion, and is also a Tax Advisor for various startups, specializing in international tax advisory services to Indian and overseas entities. With a keen eye for nuances in tax law, she is sought after by businesses with a global presence for her expertise in structuring international transactions."
        />
      </div>
      <div className="flex flex-col items-center pb-12 text-xl md:text-2xl">
        <p className="text-[#314256] text-2xl md:text-4xl uppercase">
          Our Team
        </p>
      </div>
      <div className={styles.cards_container}>
        <Card name="Venkatesh R" img={venkatesh} />
        <Card name="Deepsi Agarwal" img={deepsi} />
        <Card name="Kanhaiya Bharti" img={kanhaiya} />
        <Card name="Neha Budhgavi" img={neha} />
        <Card name="Naveen Thomas" img={naveen} />
        <Card name="Mahammad Seikh" img={mahammad} />
        <Card name="Shiva K" img={shiva} />
        <Card name="Narendra Reddy" img={narendra} />
        <Card name="Aslam Abdul Rahman" img={aslam} />
        <Card name="Malika Seth" img={mailika} />
      </div>
    </>
  );
};

export default Detail;
