import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import { useEffect, useState } from "react";
import styles from "../styles/layout.module.css";
import modal_image from "./modal_image.jpg";


const Layout = () => {
  const [modal, setModal] = useState(false);

  

  useEffect(() => {
    setTimeout(() => {
      // setModal(true);
    }, 5000);
  }, []);

  

  return (
    <>
      <div style={{ position: "relative" }}>
        
        <Navbar />
        <Outlet />
        <Footer />
        {modal && (
          <div className={styles.modal}>
            <div className={styles.modal_card}>
              <img src={modal_image} alt="" />
              <div
                className={styles.modal_close}
                onClick={() => setModal(false)}
              >
                X
              </div>
              <a href="tel:+919632438007">Contact us</a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Layout;
