import checker from "../../assets/checkerH.png";
import styles from "../../styles/services.module.css";

interface StartupProps {
  bg?: string;
  rev?: boolean;
  title: string;
  text: string;
  id: string;
  img: any;
}

const Startup = ({ bg, rev = false, title, text, id, img }: StartupProps) => {
  return (
    <>
      <div
        id={id}
        className={`flex ${
          rev ? "flex-col-reverse md:flex-row-reverse" : "flex-col md:flex-row"
        } w-full py-10 md:py-20 px-8 md:px-0 gap-y-8 md:gap-y-0`}
        style={{
          backgroundColor: bg,
        }}
      >
        <img
          src={img}
          className={`object-cover object-center w-full md:w-1/2 ${styles.start_up_img}`}
          alt=""
        />
        <div
          className={`${
            bg ? "text-white" : "text-[#4F37F5]"
          } flex flex-col justify-center mx-auto gap-y-4 md:gap-y-8 max-w-md`}
        >
          <h3 className="text-2xl md:text-4xl uppercase xl:ml-6">{title}</h3>
          <p className="text-sm md:text-base xl:ml-6 text-justify whitespace-pre-line">
            {text}
          </p>
        </div>
      </div>
    </>
  );
};

export default Startup;
