import { Link, useNavigate } from "react-router-dom";
import checker from "../../assets/checker.png";
import img1 from "../../assets/images/images/startupAdvisory.jpg";
import img2 from "../../assets/images/images/finance1.jpg";
import img3 from "../../assets/images/images/finance2.jpg";
import img4 from "../../assets/images/images/other.jpg";
import styles from "../../styles/home.module.css";

interface CardProps {
  text: string;
  uri: string;
  img: any;
}

const Services = () => {
  const navigate = useNavigate();

  const Card = ({ text, uri, img }: CardProps) => {
    return (
      <div
        className={styles.service_card}
        onClick={() => {
          navigate("/services" + uri);
        }}
      >
        <div className={styles.service_card_overlay}></div>
        <img src={img} alt="" />
        <p className="px-2 text-center text-white opacity-100">{text}</p>
      </div>
    );
  };

  return (
    <>
      <div className={styles.service}>
        <p className="text-2xl md:text-4xl">
          Services to Build & Grow your Business
        </p>
        {/* <div className="flex flex-row justify-between flex-wrap gap-4  lg:ml-4"> */}
        <div className={styles.service_card_container}>
          <Card
            text="Startup Advisory & Investment Readiness"
            uri="#startup"
            img={img1}
          />
          <Card text="Finance Controllership" uri="#control" img={img2} />
          <Card text="Finance Strategy" uri="#strategy" img={img3} />
          <Card text="Others" uri="#service" img={img4} />
        </div>
        <Link
          to={"/services"}
          className="mt-4 lg:mt-8 w-fit px-4 py-2 mx-auto border-2 border-[#CD9803]"
        >
          View all Services
        </Link>
      </div>
    </>
  );
};

export default Services;
