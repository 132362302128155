// interface CommitmentsProps {}

import TestimonialCard from "../../components/TestimonialCard";
import img1 from "../../assets/images/images/boat.jpg";
import img3 from "../../assets/images/images/handshake.jpg";
import img2 from "../../assets/images/images/laptop.jpg";
import styles from "../../styles/testimonial.module.css";

const Commitments = () => {
  return (
    <>
      <div>
        <div className="bg-[#4F37F5] text-white py-8 md:py-16 flex flex-col px-6 md:px-28">
          <p className="uppercase text-2xl md:text-4xl self-start xl:ml-12">
            OUR COMMITMENT
          </p>
          <div className={styles.wrapper}>
            <TestimonialCard
              text="Every engagement is headed by an experienced client manager who guides a team of professionals, including a client in-charge (your SPOC) and a client executioner.							"
              title="Team of Experts"
              img={img1}
            />
            <TestimonialCard
              text="We are a boutique firm with a team of highly qualified finance professionals, headed by founders with extensive Big4 (global accounting firms) experience. We pride ourselves on providing premium services with value addition and multiple levels of review of work product, to deliver consistent excellence."
              title="Premium Services"
              img={img2}
            />
            <TestimonialCard
              text="We prioritize our clients by providing personalized services and solutions tailored to their unique needs.
							We’re committed to delivering good outcomes and go above and beyond to ensure successful implementation of solutions and achievement of your financial goals."
              title="We Care"
              img={img3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Commitments;
