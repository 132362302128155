import Hero from "../../components/Hero";
import Detail from "./Detail";
import Values from "./Values";
// import video from "../../assets/Bion2.webm";
// import video2 from "../../assets/Bion2.mp4";
import { ScrollRestoration } from "react-router-dom";

// interface TeamProps {}

const Team = () => {
  return (
    <>
      <ScrollRestoration />
      <Hero
        text="OUR MISSION"
        isCompanyPage={true}
        videoSrc={
          "https://business-1-images.s3.ap-south-1.amazonaws.com/bion/hero2.mp4"
        }
        styless={{ color: "#303030" }}
      />
      <Values />
      <Detail />
    </>
  );
};

export default Team;
