import { useEffect, useState } from "react";
import { ScrollRestoration, useParams } from "react-router-dom";
// import styles from "../../styles/blogid.module.css";
import { getBlogById } from "./apiCall";
import { imageLink } from "./Index";
import styles from "../../styles/privacy.module.css";
import styless from "../../styles/blogid.module.css";

const BlogById = () => {
  let { id } = useParams();

  const [data, setData] = useState<any>({});

  useEffect(() => {
    getBlogById(id)
      .then((res) => {
        setData(res.blogs[0]);
      })
      .catch((e) => console.log(e));
  }, [id]);

  return (
    <>
      <ScrollRestoration />
      {data?.title && (
        <div className="flex flex-col items-center gap-y-8 pb-4 md:pb-8 bg-[#fff] relative">
          <div
            style={{
              position: "absolute",
              height: "70vh",
              background: "rgba(0,0,0,.3)",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 className={styless.blog_title}>{data.title}</h1>
          </div>
          <img
            src={`${imageLink}/${data.image}`}
            alt=""
            className="w-full object-cover"
            style={{ height: "70vh" }}
          />
          <div
            className="w-4/5 -translate-y-16 md:-translate-y-28 text-sm lg:text-base z-20 bg-white"
            style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
          >
            {
              <p
                style={{
                  paddingTop: "30px",
                  paddingInline: "9vw",
                  color: "grey",
                }}
              >
                Author : {data?.others?.author ? data.others.author : ""}
              </p>
            }
            <div
              id="blog_data"
              className={styles.content_container}
              dangerouslySetInnerHTML={{
                __html: data.body?.replace(/&nbsp;/g, " "),
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogById;
