import Contact from "../../components/Contact";
import Hero from "../../components/Hero";
import Marque from "../../components/Marque";
import Blogs from "./Blogs";
import Info from "./Info";
import Services from "./Services";
import Testimonials from "./Testimonials";

import { ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllBlogs } from "../blog/apiCall";
import createUrl from "../../utils/createUrl";

const Home = () => {
  const [blog, setBlog] = useState([]);
  const location = useLocation().hash;
  const navigate = useNavigate();

  useEffect(() => {
    let pageNo: any = 1;

    getAllBlogs(pageNo)
      .then((data) => {
        console.log("blogs are : ", data.blogs);
        createUrl(data.blogs[0].title);
        setBlog(
          data.blogs
            .sort((a: any, b: any) => a.priority - b.priority)
            .splice(0, 3)
        );
        if (location === "#contact") {
          navigate("#contact");
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Hero
        text="We empower your business with our financial expertise."
        fontSize="6rem"
        videoSrc={
          window.innerWidth < 800
            ? process.env.REACT_APP_BION_1_MP4
            : process.env.REACT_APP_BION_1_WEBM
        }
        isLandingPage={true}
      />
      <div>
        <Info />
      </div>
      <Services />
      <Marque title="Clients" bg="#EF8600" textColor="#fff" />
      <Testimonials />

      {blog.length !== 0 && <Blogs data={blog} />}
      <Contact />
    </>
  );
};

export default Home;
