import { useState } from "react";
import StyledInputbox from "./StyledInputbox";
import { submitContact } from "./apiCalls";
import { Toaster, toast } from "react-hot-toast";
import styles from "../styles/home.module.css";
import email from "../assets/icon/email.svg";
import mobile from "../assets/icon/mobile.svg";
import landline from "../assets/icon/landline.svg";

// interface ContactProps {}

const Contact = () => {
  const [value, setValue] = useState({
    firstName: "",
    personalEmail: "",
    mobileNumber: "",
    reason1: "",
    reason2: "",
  });

  const [success, setSuccess] = useState("");

  const { firstName, personalEmail, mobileNumber, reason1, reason2 } = value;

  const handleChange = (name: string) => (e: any) => {
    const val = e.target.value;
    setValue({ ...value, [name]: val });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (firstName === "" || personalEmail === "" || mobileNumber === "") {
      setSuccess("fields are required!");
      handleToast();
      return;
    }
    setSuccess("");
    submitContact({ firstName, personalEmail, mobileNumber, reason1, reason2 })
      .then((res) => {
        console.log("res is : ", res);
        // setSuccess(res.message);
        // handleToast();
        toast.success("We have received you message.");
        if (res.success !== false) {
          setValue({
            ...value,
            firstName: "",
            personalEmail: "",
            mobileNumber: "",
            reason1: "",
            reason2: "",
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const handleToast = () => {
    return toast(success);
  };

  return (
    <>
      <Toaster gutter={12} />
      <div
        style={{ position: "relative" }}
        className="flex flex-col lg:flex-row md:items-center px-6 md:px-0 bg-[#FCFCFC] gap-y-4 py-12 md:py-16"
      >
        <div
          id="contact"
          style={{
            position: "absolute",
            width: "10px",
            height: "70px",
            background: "transparent",
            top: "-70px",
            zIndex: "-100",
          }}
        ></div>
        <p
          className="lg:-rotate-90 text-4xl md:text-7xl text-[#cd9803] lg:ml-6"
          style={{ whiteSpace: "nowrap" }}
        >
          Contact us
        </p>
        <div className={styles.contact}>
          <div className="max-w-xs md:max-w-sm flex flex-col justify-center gap-y-2">
            <div className="text-lg text-[#314256] flex items-center">
              <img src={email} alt="" className="w-5 h-5 mr-2" />
              <a href="mailto:contact@bionadvisors.com">
                contact@bionadvisors.com
              </a>
            </div>
            <div className="text-lg text-[#314256] flex items-center">
              <img src={mobile} alt="" className="w-5 h-5 mr-2" />
              <p>
                <a href="tel:+919632438007">+91 96324 38007</a>,&nbsp;
                <a href="tel:+919844987240">+91 98449 87240</a>
              </p>
            </div>
            <div className="text-lg text-[#314256] flex items-center">
              <img src={landline} alt="" className="w-5 h-5 mr-2" />
              <a href="tel:08043751578">080-4375 1578</a>
            </div>
            <div className="text-lg text-[#314256]">
              <p>Visit us at:</p>
              <a
                href="https://maps.app.goo.gl/jtS6ANZR9n4VUmbWA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1 className="text-base text-[#314256] underline">India</h1>
                <p>
                  <span>Bion Advisors Private Limited,</span> <br />
                  Unit No. 1F-01, 1st Floor, Century Central, Kanakapura Main
                  Road, Near Konankunte Cross Metro station, Bengaluru,
                  Karnataka - 560062
                </p>
              </a>
              <a
                href="https://maps.app.goo.gl/Es95GmAU9cSR7EFJ8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h1 className="text-base text-[#314256] underline mt-5">UAE</h1>
                <p>
                  <span>Bion Advisors LLC,</span> <br />
                  Sharjah Media City (Shams)
                  <br />
                  P.O. Box 515000 Sharjah, UAE
                </p>
              </a>
            </div>
            {/* <div>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.1028737722045!2d77.5907763802366!3d12.965268582420196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d8252fb9e3%3A0x29b91d1e2d3512fa!2s6%2C%20Raja%20Ram%20Mohan%20Roy%20Rd%2C%20Sampangi%20Rama%20Nagara%2C%20Richmond%20Town%2C%20Bengaluru%2C%20Karnataka%20560025!5e0!3m2!1sen!2sin!4v1685094369670!5m2!1sen!2sin"
                height="300"
                style={{ border: 0, width: "100%" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full md:w-[400px]"
              ></iframe>
            </div> */}
          </div>
          <div className="flex flex-col gap-y-8 w-full md:w-72 xl:w-96">
            <StyledInputbox
              type="text"
              placeHolder="Full Name"
              val={firstName}
              onChanged={handleChange("firstName")}
            />
            <StyledInputbox
              type="email"
              placeHolder="Email Address"
              val={personalEmail}
              onChanged={handleChange("personalEmail")}
            />
            <StyledInputbox
              type="text"
              placeHolder="Mobile Number"
              val={mobileNumber}
              onChanged={handleChange("mobileNumber")}
            />
            <StyledInputbox
              type="text"
              placeHolder="Subject"
              val={reason1}
              onChanged={handleChange("reason1")}
            />
            <textarea
              name=""
              id=""
              rows={6}
              className="border border-[#314256] p-4"
              placeholder="Message"
              value={reason2}
              onChange={handleChange("reason2")}
            ></textarea>
            <button
              className="text-[#FCFCFC] text-base bg-[#CD9803] h-[46px]"
              onClick={onSubmit}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
